<template>
  <nuxt :style="theme" />
</template>

<script>
import { capitalize, kebabCaseToSpace } from '~/core/helper/StringHelper';
import { mapGetters } from 'vuex';

export default {
  name: 'default',
  data() {
    return {
      userCountry: '',
      bodyStyles: this.theme,
    };
  },
  async fetch() {
    const response = await this.$axios.get(`${process.env.API_BASE_URL}/country-detect`);
    const { country } = response.data;
    this.userCountry = country;
  },
  computed: {
    ...mapGetters({
      theme: 'general/getTheme',
    }),
  },
  head() {
    if (this.userCountry === 'MY') {
      return { title: 'Qoala - Insurans Dimana Jua Anda Berada' };
    }

    if (this.$i18n.locale === 'id') {
      const pageName = capitalize(kebabCaseToSpace(((this.$route || {}).path || '').split('/').pop()));
      return {
        title: `Halaman ${pageName} Qoala | Insurtech Terbaik di Indonesia`,
        description: `Halaman ini berisi tentang ${pageName} Qoala yang menjadi salah satu Insurtech Terbaik di Indonesia`,
        meta: [
          {
            hid: 'og:url',
            property: 'og:url',
            content: 'https://www.qoala.app' + this.$route.path,
          },
        ],
      };
    }
    return {
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://www.qoala.app' + this.$route.path,
        },
      ],
    };
  },
};
</script>

<style>
html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
</style>
