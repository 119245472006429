const EMPTY_STRING = '';
const SPACE_STRING = ' ';

export function capitalize(str: string) {
  return str
    .split(EMPTY_STRING)
    .reduce(
      (result, splitString, index) =>
        (result += index === 0 ? (splitString || EMPTY_STRING).toUpperCase() : splitString),
      EMPTY_STRING
    );
}

export function kebabCaseToSpace(str: string) {
  return str.replace(/-/g, SPACE_STRING);
}
